export default [
  {
    title: 'Rodada em andamento',
    route: 'current-round',
    icon: 'PlayIcon',
  },
  {
    title: 'Proximas Rodadas',
    route: 'next-rounds',
    icon: 'FastForwardIcon',
  },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]
